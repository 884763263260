
</script>
<template>

<div class="p-3 mx-1 bg-danger" id> 
    <h3 class="text-center" style="color: #fff;line-height: 60px;">លទ្ធផលឆ្នោតខ្មែរ5D:</h3>
            <table class="table table-danger">            
           <template>
                <thead>
                    <tr class="bg-danger">
                    <th  colspan="4" scope="col" style="line-height: 40px;">ចេញថ្ងៃ: {{r1.date   || "........"}} &nbsp; &nbsp;  ម៉ោង​: {{r1.time   || "........"}}  </th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white">
                       <Spin v-if="r1.ticking>=1 && r1.pcl_results_a<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី១:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r1.pcl_results_a" v-else-if="r1.pcl_results_a" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី១:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី១ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th  >&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                    <tr class="bg-white">
                       <Spin v-if="r1.ticking>=1 && r1.pcl_results_b<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី២:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r1.pcl_results_b" v-else-if="r1.pcl_results_b" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី២:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី២ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th>&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                    <tr class="bg-white">
                       <Spin v-if="r1.ticking>=1 && r1.pcl_results_c<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៣:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r1.pcl_results_c" v-else-if="r1.pcl_results_c" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៣:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៣ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th  >&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                    <tr class="bg-white">
                       <Spin v-if="r1.ticking>=1 && r1.pcl_results_d<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៤:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r1.pcl_results_d" v-else-if="r1.pcl_results_d" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៤:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៤ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th  >&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                </tbody>
           </template>
           <!-- 2nd row  -->
           <template>
                <thead>
                    <tr class="bg-danger">
                        <th  colspan="2" scope="col" style="line-height: 40px;">ចេញថ្ងៃ: {{r2.date   || "........"}} &nbsp; &nbsp;  ម៉ោង​: {{r2.time   || "........"}}  </th>   
                    </tr>
                </thead>
                 <tbody>
                    <tr class="bg-white">
                       <Spin v-if="r2.ticking>=1 && r2.pcl_results_a<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី១:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r2.pcl_results_a" v-else-if="r2.pcl_results_a" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី១:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី១ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th  >&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                    <tr class="bg-white">
                       <Spin v-if="r2.ticking>=1 && r2.pcl_results_b<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី២:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r2.pcl_results_b" v-else-if="r2.pcl_results_b" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី២:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី២ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th>&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                    <tr class="bg-white">
                       <Spin v-if="r2.ticking>=1 && r2.pcl_results_c<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៣:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r2.pcl_results_c" v-else-if="r2.pcl_results_c" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៣:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៣ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th  >&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                    <tr class="bg-white">
                       <Spin v-if="r2.ticking>=1 && r2.pcl_results_d<1 " >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៤:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Spin>
                       <Result :res="r2.pcl_results_d" v-else-if="r2.pcl_results_d" >&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៤:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</Result>
                       <th  v-else>&nbsp;&nbsp;&nbsp;&nbsp;បង្វិលលើកទី៤ : &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<slot></slot></th>
                       <th  >&nbsp;&nbsp;&nbsp;&nbsp;១ x ៦០០០០ដង &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                    </tr>
                </tbody>
           </template>
           <!-- 3rd row  --
           <template>
                <thead>
                  <tr class="bg-danger">
                        <th  colspan="2" scope="col">ចេញទី: {{r3.draw_id  || "........" }} &nbsp; &nbsp; ចេញថ្ងៃ: {{r3.date   || "........"}} &nbsp; &nbsp;   ម៉ោង​: {{r3.time   || "........"}}  </th>   
                    </tr>
                </thead>
                 <tbody>
                    <tr class="bg-white">
                       <Spin v-if="r3.ticking>=1 && r3.pcl_results_a<1 " >A1:&nbsp;</Spin>
                       <Result :res="r3.pcl_results_a" v-else-if="r3.pcl_results_a" >A1:&nbsp;</Result>
                       <th  v-else>A1 : &nbsp; <slot></slot></th>

                       <Spin v-if="r3.ticking>=1 && r3.pcl_results_c<1 " >C3:&nbsp;</Spin>
                       <Result :res="r3.pcl_results_c" v-else-if="r3.pcl_results_c" >C3:&nbsp;</Result>
                       <th  v-else>C3 : &nbsp; <slot></slot></th>

                    </tr>
                    <tr class="bg-white">              
                        <Spin v-if="r3.ticking>=1 && r3.pcl_results_b<1">B2:&nbsp;</Spin>
                       <Result :res="r3.pcl_results_b" v-else-if="r3.pcl_results_b" >B2:&nbsp;</Result>
                       <th  v-else>B2 : &nbsp; <slot></slot></th>

                        <Spin v-if="r3.ticking>=1 && r3.pcl_results_d<1">D4:&nbsp;</Spin>
                       <Result :res="r3.pcl_results_d" v-else-if="r3.pcl_results_d" >D4:&nbsp;</Result>
                       <th  v-else>D4 : &nbsp; <slot></slot></th>
                    </tr>
                </tbody>
           </template>
           <!-- 4th row  --
           <template>
                <thead>
                 <tr class="bg-danger">
                        <th  colspan="2" scope="col">ចេញទី: {{r4.draw_id  || "........" }} &nbsp; &nbsp; ចេញថ្ងៃ: {{r4.date   || "........"}} &nbsp; &nbsp;   ម៉ោង​: {{r4.time   || "........"}}  </th>   
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white">
                       <Spin v-if="r4.ticking>=1 && r4.pcl_results_a<1 " >A1:&nbsp;</Spin>
                       <Result :res="r4.pcl_results_a" v-else-if="r4.pcl_results_a" >A1:&nbsp;</Result>
                       <th  v-else>A1 : &nbsp; <slot></slot></th>

                       <Spin v-if="r4.ticking>=1 && r4.pcl_results_c<1 " >C3:&nbsp;</Spin>
                       <Result :res="r4.pcl_results_c" v-else-if="r4.pcl_results_c" >C3:&nbsp;</Result>
                       <th  v-else>C3 : &nbsp; <slot></slot></th>

                    </tr>
                    <tr class="bg-white">              
                        <Spin v-if="r4.ticking>=1 && r4.pcl_results_b<1">B2:&nbsp;</Spin>
                       <Result :res="r4.pcl_results_b" v-else-if="r4.pcl_results_b" >B2:&nbsp;</Result>
                       <th  v-else>B2 : &nbsp; <slot></slot></th>

                        <Spin v-if="r4.ticking>=1 && r4.pcl_results_d<1">D4:&nbsp;</Spin>
                       <Result :res="r4.pcl_results_d" v-else-if="r4.pcl_results_d" >D4:&nbsp;</Result>
                       <th  v-else>D4 : &nbsp; <slot></slot></th>
                    </tr>
                </tbody>
           </template>
           <!-- 5th row  --
           <template>
                <thead>
                   <tr class="bg-danger">
                        <th  colspan="2" scope="col">ចេញទី: {{r5.draw_id  || "........" }} &nbsp; &nbsp; ចេញថ្ងៃ: {{r5.date   || "........"}} &nbsp; &nbsp;   ម៉ោង​: {{r5.time   || "........"}}  </th>   
                    </tr>
                </thead>
                <tbody>
                    <tr class="bg-white">
                       <Spin v-if="r5.ticking>=1 && r5.pcl_results_a<1 " >A1:&nbsp;</Spin>
                       <Result :res="r5.pcl_results_a" v-else-if="r5.pcl_results_a" >A1:&nbsp;</Result>
                       <th  v-else>A1 : &nbsp; <slot></slot></th>

                       <Spin v-if="r5.ticking>=1 && r5.pcl_results_c<1 " >C3:&nbsp;</Spin>
                       <Result :res="r5.pcl_results_c" v-else-if="r5.pcl_results_c" >C3:&nbsp;</Result>
                       <th  v-else>C3 : &nbsp; <slot></slot></th>

                    </tr>
                    <tr class="bg-white">              
                        <Spin v-if="r5.ticking>=1 && r5.pcl_results_b<1">B2:&nbsp;</Spin>
                       <Result :res="r5.pcl_results_b" v-else-if="r5.pcl_results_b" >B2:&nbsp;</Result>
                       <th  v-else>B2 : &nbsp; <slot></slot></th>

                        <Spin v-if="r5.ticking>=1 && r5.pcl_results_d<1">D4:&nbsp;</Spin>
                       <Result :res="r5.pcl_results_d" v-else-if="r5.pcl_results_d" >D4:&nbsp;</Result>
                       <th  v-else>D4 : &nbsp; <slot></slot></th>
                    </tr>
                </tbody>
           </template>
             -->   
            </table>
            <button @click="get_result" style="display: none" id="pushs">hide and sick</button>
        </div>
</template>
<script>
import Pusher from "pusher-js";
import Admin from "./Admin";
import Spin from "./Spin";
import Result from "./Result";
        var pusher = new Pusher('cb383bc02f2dc131130c', {
            cluster: 'ap2'
            });
            var channel = pusher.subscribe('live-update-data');
            channel.bind('LiveUpdate', function(data) {
                 document.getElementById('pushs').click();
            
            });
        export default {
        components:{
            Admin,Spin,
                Result
        },
 
               data(){
            return {
                r1 : {pcl_results_a:"",pcl_results_b: "",pcl_results_c:"",pcl_results_d: "",date:"",draw_id:"",ticking:0,time:""},
                r2 : {pcl_results_a:"",pcl_results_b: "",pcl_results_c:"",pcl_results_d: "",date:"",draw_id:"",ticking:0,time:""},
                r3 : {pcl_results_a:"",pcl_results_b: "",pcl_results_c:"",pcl_results_d: "",date:"",draw_id:"",ticking:0,time:""},
                r4 : {pcl_results_a:"",pcl_results_b: "",pcl_results_c:"",pcl_results_d: "",date:"",draw_id:"",ticking:0,time:""},
                r5 : {pcl_results_a:"",pcl_results_b: "",pcl_results_c:"",pcl_results_d: "",date:"",draw_id:"",ticking:0,time:""},
            }
        },
        methods:{
            fire(){
                this.testdata = "updated"
                  alert(" this is form the method on test");
            },
            get_result(){
                 axios.get("/get_result").then(
                   (res)=>{
                       if(res.data[0]){
                          this.r1= res.data[0] ; 
                       }
                       if(res.data[1]){
                          this.r2= res.data[1] ; 
                       }
                       if(res.data[2]){
                          this.r3= res.data[2] ; 
                       }
                       if(res.data[3]){
                          this.r4= res.data[3] ; 
                       }
                       if(res.data[4]){
                          this.r5= res.data[4] ; 
                       } 
                                          
                    }
               ).catch(res=>{                  
                    this.$notify({
                        group: 'foo',
                        title: 'Failed',
                        type: "warn",
                        text: 'Something Wrong..!!'
                        });                 
            })
      
     
        }},
        created() {
         
            this.get_result();            
             
        }
    }
</script>
