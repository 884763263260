<template>
<div class="container py-5  bg-white" style="padding-top: 1rem!important;">
    <form v-on:submit.prevent="add_draw">
        <h4 style="color: #d40000;text-decoration: underline;">Add Draw</h4>
        <div class="form-row">
            <div class="form-group  col-3">
            <label >Draw ID</label>
            <input type="text" required class="form-control  form-control-sm"  placeholder="Draw ID" v-model="draw_id">
            </div>
            <div class="form-group col-3">
            <label >Date</label>
            <input type="date" required class="form-control  form-control-sm" v-model="date" >
            </div>
            <div class="form-group col-3">
            <label >Time</label>
            <div>
            <select v-model="time" required class="form-control  form-control-sm">
            <option disabled value="">Please select Time</option>
            <option value="1:15PM">01:15PM</option>
            <option value="6:15PM">06:15PM</option>
            </select>
            </div>
            </div>
            <div class="form-group col-3">
            <label >&nbsp;</label><br>
            <button type="submit" class="btn btn-info btn-sm ">Add</button>
            </div>
        </div>
    </form>
      <div class="row mt-3 " v-if="update_status">
       <div class="col-12">
            <h4 style="color: #d40000;text-decoration: underline;">Update Draw Result</h4>
              <form v-on:submit.prevent="update_result">
                    <div class="form-row">
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Draw ID:</label>
                            <input type="number"  class="form-control  form-control-sm" v-model="draw_id"  style="font-size: 12px;text-align: center;font-weight: bold;color:#002ba8;">
                        </div>
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Date:</label>
                            <input type="date"  class="form-control  form-control-sm" v-model="date"  style="font-size: 12px;text-align: center;font-weight: bold;color:#002ba8;">
                        </div>
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Time:</label>
                            <select v-model="time" required class="form-control  form-control-sm" style="font-size: 12px;text-align: center;font-weight: bold;color:#002ba8;">
                            <option disabled value="">Please select Time</option>
                            <option value="1:15PM">01:15PM</option>
                            <option value="6:15PM">06:15PM</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-row">
                        
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Result A1:</label>
                            <input type="number" min="00000" max="99999"   class="form-control  form-control-sm" v-model="pcl_results_a" placeholder="A1" style="font-size: 20px;text-align: center;font-weight: bold;color: #f00;">
                        </div>
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Result B2:</label>
                            <input type="number" min="00000" max="99999"   class="form-control  form-control-sm" v-model="pcl_results_b" placeholder="B2" style="font-size: 20px;text-align: center;font-weight: bold;color: #f00;">
                        </div>
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Result C3:</label>
                            <input type="number" min="00000" max="99999"   class="form-control  form-control-sm" v-model="pcl_results_c" placeholder="C3" style="font-size: 20px;text-align: center;font-weight: bold;color: #f00;">
                        </div>
                        <div class="form-group  col-2">
                            <label style="color: #ff0000;font-weight: 500;">Result D4:</label>
                            <input type="number" min="00000" max="99999"   class="form-control  form-control-sm" v-model="pcl_results_d" placeholder="D4" style="font-size: 20px;text-align: center;font-weight: bold;color: #f00;">
                        </div>
                        <div class="form-group  col-1">
                            <label >&nbsp;</label><br>
                             <input type="checkbox"  class="form-control  form-control-sm" @click="ticking" v-model="checked">
                        </div>
                        <div class="form-group  col-1">
                            <label >&nbsp;</label><br>
                             <button type="submit" class="btn btn-info btn-sm ">Update</button>
                        </div>
                    </div>
                </form>
       </div>
    </div>
    <div class="row" >
        <div class="col-12">
            <h4>Draw List</h4>
                    <table class="table table-sm text-center">
                        <thead>
                            <tr>
                                <th scope="col" style="background: #3d63d2;color: #fff;">Draw ID</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">Date</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">Time</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">A1</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">B2</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">C3</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">D4</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">Edit</th>
                                <th scope="col" style="background: #3d63d2;color: #fff;">Delete</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr v-for="item in draw_list" :key="item.index">
                                <th scope="row">{{item.draw_id}}</th>
                                <td>{{item.date}}</td>
                                <td>{{item.time}}</td>
                                <td>{{item.pcl_results_a}}</td>
                                <td>{{item.pcl_results_b}}</td>
                                <td>{{item.pcl_results_c}}</td>
                                <td>{{item.pcl_results_d}}</td>
                               
                                <td>
                                    <button @click="update_draw(item.id,item.ticking,item.draw_id,item.date,item.time,item.pcl_results_a,item.pcl_results_b,item.pcl_results_c,item.pcl_results_d)" class="btn btn-sm btn-info">Edit</button>
                                </td>
                                <td>
                                    <a @onclick="$id('id', this.id)" class="btn btn-sm btn-danger">Delete</a>
                                </td>
                            </tr>

                        </tbody>
            </table>
        </div>
    </div>
  
    <div class="pb-5 "></div>

    <notifications group="foo" />
</div>
</template>
<script>
import axios from "axios";
import Notifications from 'vue-notification';

Vue.use(Notifications)
export default {
    // props: [link],
    data(){
        return({
            checked: false,
            draw_id: "",
            date: "",
            time: "",
            pcl_results_a: "",
            pcl_results_b: "",
            pcl_results_c: "",
            pcl_results_d: "",
            draw_list : [],
            update_id: "",
            update_status: false,
        })
    },
    methods:{
        add_draw(){
            var [h,m] = this.time.split(":");
            var r_h= (h%12+12*(h%12==0));
            var am_pm = h >= 12 ? '' : '';
            this.time= r_h  + ":" + m + " " +am_pm;

            axios.post("/post_data",{
                draw_id: this.draw_id,
                date: this.date,
                time: this.time,
            })
            .then((res)=>{
                axios.get("/get_data").then(
                   (r)=>{
                       this.draw_list= r.data;
                        this.draw_id = "";
                        this.date = "";
                        this.time = "";
                        this.$notify({
                            type: 'success',
                            group: 'foo',
                            title: 'Success',
                            text: 'Added Successfully..!!'
                            });
                   }
               ).catch()

            })
            .catch()
       },
       update_draw(id,ticking,draw_id,date,time,pcl_results_a,pcl_results_b,pcl_results_c,pcl_results_d){
            this.update_id = id;

            this.update_status = true;
            this.checked = ticking;
            this.draw_id = draw_id;
            this.date = date;
            this.time = time;
            this.pcl_results_a = pcl_results_a;
            this.pcl_results_b = pcl_results_b;
            this.pcl_results_c = pcl_results_c;
            this.pcl_results_d = pcl_results_d;
        },       
        ticking(){
            axios.post("/ticking",{
                id: this.update_id,
                ticking: this.checked
            })
            .then(res=>{
                console.log(res);
            })
            .catch();
        },
        update_result(){
             axios.post("/updateData",{
              id:  this.update_id,
              draw_id:this.draw_id,
              date:this.date,
              time:this.time,
              pcl_results_a:this.pcl_results_a,
              pcl_results_b:this.pcl_results_b,
              pcl_results_c:this.pcl_results_c,
              pcl_results_d:this.pcl_results_d
            }).then(res=>{
                   this.$notify({
                        group: 'foo',
                        title: 'Success',
                        text: 'Upadated Successfully..!!'
                    });

                     axios.get("/get_data").then(
                   (r)=>{
                       this.draw_list= r.data;
                      
                        this.$notify({
                            type: 'success',
                            group: 'foo',
                            title: 'Success',
                            text: 'Added Successfully..!!'
                            });


                   }
               ).catch()
                    this.checked= true;
                    this.update_status = true;

            }).catch(res=>{
                    this.$notify({
                        group: 'foo',
                        title: 'Failed',
                        type: "warn",
                        text: 'Something Wrong..!!'
                        });
            })

        }

    },

    created(){
             axios.get("/get_data").then(
                   (res)=>{
                       this.draw_list= res.data;

                   }
               ).catch(res=>{
                    this.$notify({
                        group: 'foo',
                        title: 'Failed',
                        type: "warn",
                        text: 'Something Wrong..!!'
                        });
            })
    }

}
</script>

<style>
.btn:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}
input[type="checkbox"], textarea {
              outline: none;
              box-shadow:none !important;
              border:1px solid #ccc !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
